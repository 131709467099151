import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaStar } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | RoadRover Rentals
			</title>
			<meta name={"description"} content={"A RoadRover Rentalsnál megértjük, hogy az utazás legalább olyan fontos, mint a célállomás. Autóbérlési szolgáltatásunkat úgy alakítottuk ki"} />
			<meta property={"og:title"} content={"Home | RoadRover Rentals"} />
			<meta property={"og:description"} content={"A RoadRover Rentalsnál megértjük, hogy az utazás legalább olyan fontos, mint a célállomás. Autóbérlési szolgáltatásunkat úgy alakítottuk ki"} />
			<meta property={"og:image"} content={"https://oldis-star.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://oldis-star.com/img/859797.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://oldis-star.com/img/859797.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://oldis-star.com/img/859797.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://oldis-star.com/img/859797.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://oldis-star.com/img/859797.png"} />
			<meta name={"msapplication-TileImage"} content={"https://oldis-star.com/img/859797.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://oldis-star.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					RoadRover Rentals
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					A RoadRover Rentalsnál megértjük, hogy az utazás legalább olyan fontos, mint a célállomás. Autóbérlési szolgáltatásunkat úgy alakítottuk ki, hogy zökkenőmentes és személyre szabott élményt nyújtsunk Önnek, biztosítva, hogy utazása problémamentes és élvezetes legyen.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Csatlakozzon hozzánk
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaStar}
						margin="0px 0px 22px 0px"
						color="--dark"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Összeválogatott flotta
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						A járműválasztékunk elég változatos ahhoz, hogy megfeleljen a különböző preferenciáknak, ugyanakkor gondosan válogatott a minőség és a megbízhatóság szempontjából.
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaStar}
						margin="0px 0px 22px 0px"
						color="--dark"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						24/7 támogatás
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Elkötelezett csapatunk mindig az Ön rendelkezésére áll, hogy biztosítsa a nyugalmat a bérlés során.
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaStar}
						margin="0px 0px 22px 0px"
						color="--dark"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Átlátható árképzés
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						A RoadRover Rentalsnál azt kapja, amit lát. Árazásunk egyszerű, rejtett díjak nélkül.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Környezetbarát lehetőségek: Azoknak, akik a környezetbarátabb utazást részesítik előnyben, üzemanyag-takarékos és hibrid járműveket kínálunk.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://oldis-star.com/img/2.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});